var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewDashBoard project-route-index"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{key:"2",staticClass:"white-row"},[_c('div',{staticClass:"show-project-portal-listing"},[_c('div',{staticClass:"form-area"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row top-gen-heading"},[_c('h1',{staticClass:"global-style-heading"},[_vm._v(" Create Project Status ")])])]),_c('div',{staticClass:"col-sm-12"},[_c('form',{staticClass:"status-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"status_name"}},[_vm._v("Status Name: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.status_name),expression:"formData.status_name"}],staticClass:"form-control",class:{
                              'input-border-errors':
                                _vm.$v.formData.status_name.$dirty &&
                                !_vm.$v.formData.status_name.required
                            },attrs:{"type":"text","placeholder":"Status Name"},domProps:{"value":(_vm.formData.status_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "status_name", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v("Select Project Status Category Name: "),_c('span',[_vm._v("*")])]),_c('Select2',{class:{
                              'input-border-errors':
                                _vm.$v.formData.status_category_id.$dirty &&
                                !_vm.$v.formData.status_category_id.required
                            },attrs:{"options":_vm.status_category_option,"settings":{
                              placeholder:
                                'Select Project Status Category Name',
                              allowClear: true
                            }},model:{value:(_vm.formData.status_category_id),callback:function ($$v) {_vm.$set(_vm.formData, "status_category_id", $$v)},expression:"formData.status_category_id"}})],1)])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn btn-primary default-global-btn",attrs:{"disabled":_vm.disableSubmitBtn,"type":"submit","id":"save-form"}},[_vm._v(" Create ")]),(_vm.disableSubmitBtn)?_c('div',{staticClass:"form_submit_loader"},[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])]):_vm._e()])])])])])])])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }